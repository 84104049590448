.destination {
  border-radius: 10px;
  transition: transform 0.5s, opacity 0.5s;
  text-decoration: none;
  margin: 1.2rem;
}

.destination.focused {
  transform: scale(1.2);
  border: 1px solid #fff;
  z-index: 1;
}

.destination:hover {
  transform: scale(1.2);
  cursor: pointer;
  border: 1px solid #fff;
}

.destination-img {
  width: 300px;
  height: 200px;
  object-fit: cover;
  padding: 5px;
  border-radius: 10px;
}
