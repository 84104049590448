.about-us-h2 {
  font-weight: 700;
  font-size: 32px;
  text-align: center;
}

.section-odd {
  flex-direction: row;
}

.section-even {
  flex-direction: row-reverse;
}

.section-img {
  max-width: 100%;
  max-height: 500px;
  padding: 5px;
  border: 1px solid #ddd;
}

.section-img:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

.about-text {
  padding: 1rem;
  line-height: 0.3in;
  font-size: large;
}

.testimonial-radius {
  border-radius: 20px;
}

@media (max-width: 750px) {
  .row {
    justify-content: center;
  }
  .d-flex {
    flex-direction: column !important;
    align-items: center;
    max-width: 100vw;
  }
  .section-img {
    max-width: 90vw;
  }
}
