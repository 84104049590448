.location-wrapper {
  position: absolute;
  width: 120px;
  height: 120px;
  cursor: pointer;
  z-index: 2;
  transition: transform 0.3s ease-in-out;
}

.location-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.location-wrapper:hover {
  transform: scale(1.5);
}
