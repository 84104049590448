.bg {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.7) 100%
    ),
    url(../../assets/bg.jpg);
  background-size: cover;
  background-position: center;
  min-height: 90vh;
  color: white;
  min-height: 100vh;
}

.contact-button {
  background-color: #ffc107;
  text-decoration: none;
  font-weight: 500;
  padding: 0.7rem;
  margin-top: 0.7rem;
  border-radius: 10px;
  color: black;
}
