.contact-us-bg {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.7) 100%
    ),
    url(../../assets/contact-us.jpg);
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

.contact-us-h2 {
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  color: white;
}

.contact-para {
  font-size: large;
}
